




import { useState, useEffect, useContext } from "react";

import useAccounts from "../../hooks/useAccount";
import { AccountContext } from "../../context/AccountContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { faUserGroup, faPercentage, faMoneyBill, faVault, faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import Constants from "../../constants/Constants";

let WalletsPage = () => {

    let { wallets, platformSummary2 } = useContext(AccountContext);
    let { fetchWallets } = useAccounts();

    useEffect(() => {
        if (!wallets) {
            fetchWallets()
        }
    })
    return <>
        <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-4 ">
                <div className="flex flex-row outline outline-slate-200 rounded-[2px] gap-2">
                    <input></input>
                    <div className="flex flex-row">

                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <p className="text-left text-md font-semibold">Wallets</p>
                    <div className="grid grid-cols-2 gap-4 overflow-y-auto">
                        {
                            wallets.wallets && wallets.wallets.length > 0 ?
                                wallets.wallets.map((wallet, index) => {
                                    return <div className={` bg-slate-100 rounded-md shadow-sm`}>
                                        <div className="text-left px-1 flex flex-row  ">
                                            <p className="text-[8px]">{index + 1}</p>
                                        </div>
                                        <div className="flex flex-row gap-2 px-4 justify-between mb-2 items-center">
                                            <div className="font-bold text-black text-3xl">
                                                <p>{Constants.nairaString}{wallet?.balance?.stash.toLocaleString()}</p>
                                            </div>
                                            <div className="flex flex-col gap-1">
                                                <p className="font-semibold text-xs underline underline-offset-1">{wallet.userDetails[0].fullName}</p>
                                                <p className="text-xs text-left">{new Date(wallet.updatedAt).toDateString()}</p>
                                            </div>

                                        </div>
                                    </div>
                                })
                                : <div className="flex flex-row items-center p-4  text-center font-bold h-20 bg-slate-300">
                                    <p>No Wallets yet</p>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-2 px-2">
                <div className="flex flex-row gap-2">
                    <div className="rounded-md p-4 bg-slate-100 drop-shadow-sm flex flex-col gap-2 ">
                        <div className="flex flex-row gap-4 items-center">
                            <div className="rounded-full p-2 circle bg-slate-200">
                                <FontAwesomeIcon className="text-slate-400" icon={faVault}></FontAwesomeIcon>
                            </div>
                            <div className="text-left flex flex-col">
                                <p className="font-bold text-[30px]">{wallets ? wallets.wallets.length : 0}</p>
                                <p className="text-xs text-left">Wallets Created</p>
                            </div>
                        </div>
                        <hr></hr>
                        <div>

                        </div>
                    </div>
                    <div className="rounded-md p-4 bg-slate-100 drop-shadow-sm flex flex-col gap-2 ">
                        <div className="flex flex-row gap-4 items-center">
                            <div className="rounded-full p-2 circle bg-slate-200">
                                <FontAwesomeIcon className="text-slate-400" icon={faMoneyBillTransfer}></FontAwesomeIcon>
                            </div>
                            <div className="text-left flex flex-col">
                                <p className="font-bold text-[30px]">{Constants.nairaString}{wallets ? wallets.totalWalletBalance.toLocaleString() : 0}</p>
                                <p className="text-xs text-left">Total Wallets Value</p>
                            </div>
                        </div>
                        <hr></hr>
                        <div>

                        </div>
                    </div>

                </div>

            </div>

        </div>

    </>
}



export default WalletsPage;