import { useEffect, useRef, useState } from "react"



let HomeCardSvgComponent = ({ interval, imgs }) => {
    let [activeImg, setActiveImg] = useState(0);
    let [isRunning, setRunning] = useState(false);
    let index = 0;

    let imgRef = useRef()

    let handleAnim = async (interval) => {
        if (isRunning) {
            return;
        }
        setRunning(true)
        setInterval(() => {
            handleChange()
        }, interval)
    }

    let handleChange = async () => {
        //console.log("\n +++++++++++++++++++++++an iteration +++++++++++++++++++++")
        if (imgs) {
            if (index >= 0) {

                index = index + 1;
                if (imgRef) {
                    try{
                        imgRef.current.src = imgs[index]
                    }
                    catch(e){
                        console.log(e)
                    }
                }
                if (index == imgs.length) {
                    index = 0
                    //setActiveImg(0)
                    try {
                        if (imgRef) {
                            imgRef.current.src = imgs[index]

                        }
                    }
                    catch (e) {
                        console.log(e)
                    }
                }
            }

        }
    }

    useEffect(() => {
        if (imgRef) {
            try{
                imgRef.current.src = imgs[activeImg]
            }
            catch(e){
                console.log(e)
            }

        }
        if (interval && !isRunning) {

            handleAnim(interval)
        }


    }, [activeImg])
    return <>
        {imgs ?
            <img className="h-20 animate-bounce" ref={imgRef} ></img>
            : ""
        }
    </>
}

export default HomeCardSvgComponent