import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavBarComponent from "../components/NavBarComponent";
import { faBoltLightning, faBox, faClose, faCog, faCogs, faDoorClosed, faFileImport, faGifts, faHamburger, faHomeAlt, faInfo, faInfoCircle, faLink, faMoneyBill, faMoneyBillWave, faNavicon, faPercentage, faPlugCirclePlus, faPlusCircle, faSackDollar, faSignOutAlt, faSlash, faSpinner, faUserFriends, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useContext } from "react";
import wave1 from "../assets/img/waves-001.jpg"
import wave2 from "../assets/img/wave2.svg";
import wave3 from "../assets/img/wave3.svg";
import { faArrowAltCircleUp, faBuilding } from "@fortawesome/free-regular-svg-icons";
import InputComponent from "../components/InputComponent";
import SelectComponent3 from "../components/SelectComponent3";

import SelectComponent2 from "../components/SelectComponent2";
import { toast } from "react-toastify";
import UserService from "../api/services/UserService";
import { Link, useNavigate } from "react-router-dom";
import useAccounts from "../hooks/useAccount";
import { AccountContext } from "../context/AccountContext";
import WithdrawalComponent from "../components/modals/WithdrawalComponent";
import NewPaymentLinkComponent from "../components/modals/NewPaymentLinkComponent";
import PaymentLinkListComponent from "../components/PaymentLinkListComponent";
import TransactionListComponent from "../components/TransactionListComponent";
import SettingsPage from "./settings/SettingsPage";
import Constants from "../constants/Constants";
import NewDiscountCodeComponent from "../components/modals/NewDiscountCodeComponent";
import DiscountCodeListComponent from "../components/DiscountCodeListComponent";
import { faSlackHash } from "@fortawesome/free-brands-svg-icons";
import SidebarComponent from "../components/SidebarComponent";
import useSignIn from "../hooks/useLogin";
import SurveyModalComponent from "../components/SurveyModalComponent";
import WithdrawalListComponent from "../components/WithdrawalListComponent";
import SurveyModal from "../components/modals/SurveyModal";
import NewCustomerComponent from "../components/modals/NewCustomerComponent";
import TransactionsPage from "../components/pages/TransactionPage";
import WithdrawalPage from "../components/pages/WithdrawalPage";
import CustomerPage from "../components/pages/CustomersPage";
import BusinessPage from "../components/pages/BusinessPage";
import DiscountCodePage from "../components/pages/DiscountCodePage";
import WalletsPage from "../components/pages/WalletPage";
import Chart from "chart.js/auto"


let DashboardPage = () => {

    let [profileFetched, setProfileFetched] = useState(false);
    let { withdrawals, fullName, miniTransactions, platformSummary2, paymentLinks, users, businesses, wallets, user, wallet, discountCodes, transactions, customers, settings } = useContext(AccountContext);

    let [isLoading, setIsLoading] = useState(false);

    let [isWithdrawalModalActive, setWithdrawalModalActive] = useState(false);
    let [isNewPaymentLinkModalActive, setNewPaymentLinkModalActive] = useState(false);
    let [isNewCustomerModalActive, setNewCustomerModalActive] = useState(false);
    let [isNewDiscountCodeModalActive, setNewDiscountCodeModalActive] = useState(false);
    let [isSidebarActive, setSidebarActive] = useState(false);

    const navigate = useNavigate();
    let { fetchDiscountCodes, fetchWithdrawals, fetchPaymentLinks, fetchWallets, fetchPlatformSummary2, fetchUsers, fetchProfile, fetchUserBanks, fetchSettings, fetchTransactions, fetchCustomers } = useAccounts();
    let { banks, setBanks } = useContext(AccountContext);
    let [isLoadingAddingAccount, setIsLoadingAddingAccount] = useState(false);
    let [loadedUserBanks, setLoadedUserBanks] = useState(false);
    let [accountNumber, setAccountNumber] = useState(null);
    let [bankCode, setBankCode] = useState(null);
    let [bankName, setBankName] = useState(null);
    let [accountName, setAccountName] = useState(null);
    let [chartFilter, setChartFilter] = useState("month");

    let { logout } = useSignIn();
    let [isLogoutAskModalActive, setLogoutAskActiveModal] = useState(false);


    let [bankList, setBankList] = useState(false);
    let [isAccountResolving, setIsAccountResolving] = useState(false);
    let [activeTab, setActiveTab] = useState("home");
    let [chartLoaded, setChartLoaded] = useState(false)

    let handleLogout = async (proceed) => {
        if (proceed) logout();
        setLogoutAskActiveModal(true)

    }

    //let [isAccountResolving, setIsAccountResolving] = useState(false);
    let fetchBankList = async () => {
        let b = await UserService.fetchBankList();

        console.log(b);

        setBankList(b.data);
    }

    let handleChangeAccountNumber = async (e) => {
        console.log("account number changed", e)
        setAccountNumber(e.target.value);
        setAccountName(null);
        // resolveAccount()
    }

    let handleChangeBank = async (e) => {
        console.log("bank changed")
        setAccountName(null);
        setBankCode(e);
        let bName = (bankList.filter(o => o.code == e))[0].name;
        console.log(bName);

        setBankName(bName)
        //resolveAccount();
    }


    let handleProfileFetch = async () => {
        if (isLoading) {
            return
        }
        setIsLoading(true)
        if (!customers) {
            fetchCustomers();
        }
        if (!businesses) {
            fetchUsers();
        }
        if (!platformSummary2) {
            fetchPlatformSummary2()
        }
        if (!wallets) {
            fetchWallets();
        }
        if (!transactions) {
            fetchTransactions();
        }
        if (!user.fullName) {
            fetchProfile()
        }
        if (!loadedUserBanks) {
            fetchUserBanks();
            setLoadedUserBanks(true);
        }
        if (paymentLinks == null) {
            fetchPaymentLinks();

        }
        if (!settings) {
            fetchSettings()
        }
        if (!discountCodes) {
            fetchDiscountCodes()
        }
        if (!withdrawals) {
            fetchWithdrawals()
        }
        setProfileFetched(true)
    }


    let handleWithdrawalModal = async () => {
        if (banks) {
            if (banks.length > 0) {
                setWithdrawalModalActive(true)
            }
            else {
                toast.error("Withdarawl account has not been set");
            }
        }
        else {
            toast.error("Withdarawl account has not been set");
        }

    }
    useEffect(() => {

        if (chartLoaded == false) {
            if (chartLoaded) {
                return
            }

            /*const data = [
                { year: 2010, count: 10 },
                { year: 2011, count: 20 },
                { year: 2012, count: 15 },
                { year: 2013, count: 25 },
                { year: 2014, count: 22 },
                { year: 2015, count: 30 },
                { year: 2016, count: 28 },
            ];*/
            let data = platformSummary2 ? platformSummary2.summarizedTransactionsArray : [];

            if (platformSummary2) {
                setChartLoaded(true);
                console.log(data)
                new Chart(
                    document.getElementById('acquisitions'),
                    {
                        type: 'bar',
                        options: {
                            animation: true
                        },
                        data: {
                            labels: data.map(row => row.monthSlug),
                            datasets: [
                                {
                                    label: `Transaction amount by ${chartFilter}`,
                                    data: data.map(row => row.totalAmount),
                                    backgroundColor: "#22c55e"
                                },
                            ]
                        }
                    }
                );
            }


        }

        if (!profileFetched) {
            handleProfileFetch()
        }
        /*if (banks) {
            if (banks.length > 0) {
                toast.success("Withdrawal account exist")
                navigate("/dashboard")
            }
        }
        else {
            console.log("User does not have an account")
        }*/
        if (bankCode) {
            console.log("BankCode : ", bankCode);
            if (accountNumber) {
                if (accountNumber.length >= 10) {
                    if (accountName) {
                        return
                    }
                    if (isAccountResolving) {
                        return
                    }
                    setIsAccountResolving(true);
                    let res = async () => {


                        let resolve = await UserService.resolveAccount(accountNumber, bankCode, bankName);
                        console.log(resolve);

                        if (resolve.success) {
                            //setBankCode(null)
                            //setAccountNumber(null)
                            setAccountName(resolve.data.account_name);
                            setIsAccountResolving(false)
                        }
                        else {
                            //setBankCode(null)
                            //setAccountNumber(null)
                            toast.error(resolve.msg);
                            setIsAccountResolving(false)
                        }
                    }

                    res()
                }

            }
            else {
                console.log("noin")
            }
        }
        if (!bankList) {
            fetchBankList()
        }



    })

    let handleSwitch = (switchTo) => {
        setActiveTab(switchTo);
    }
    return <div>
        {isLogoutAskModalActive ? <SurveyModalComponent setIsModalActive={setLogoutAskActiveModal} logout={handleLogout}></SurveyModalComponent> : ""}
        {isWithdrawalModalActive ? <WithdrawalComponent setIsModalActive={setWithdrawalModalActive} ></WithdrawalComponent> : ""}
        {isNewPaymentLinkModalActive ? <NewPaymentLinkComponent setIsModalActive={setNewPaymentLinkModalActive} ></NewPaymentLinkComponent> : ""}
        {isNewCustomerModalActive ? <NewCustomerComponent setIsModalActive={setNewCustomerModalActive} ></NewCustomerComponent> : ""}
        {isNewDiscountCodeModalActive ? <NewDiscountCodeComponent setIsModalActive={setNewDiscountCodeModalActive}></NewDiscountCodeComponent> : ""}
        {isSidebarActive ? <SidebarComponent logout={handleLogout} setSidebarActive={setSidebarActive} activeTab={activeTab} handleSwitch={handleSwitch}></SidebarComponent> : ""}
        <div className="md:hidden top-0 p-4 static w-screen font-bold flex flex-row gap-2 shadow-sm shadow-slate-200/70 justify-end">
            <FontAwesomeIcon className="text-rouzo-base-color" onClick={() => handleSwitch("settings")} icon={faCog}></FontAwesomeIcon>
            {
                !isSidebarActive ?
                    <FontAwesomeIcon className="text-rouzo-base-color" onClick={() => setSidebarActive(true)} icon={faNavicon}></FontAwesomeIcon>
                    :
                    <FontAwesomeIcon className="text-rouzo-base-color" onClick={() => setSidebarActive(false)} icon={faClose}></FontAwesomeIcon>
            }

        </div>
        <SurveyModal dashboard={true}></SurveyModal>
        <div className="flex flex-col  md:flex-row">
            <div className="md:flex hidden flex-col bg-white h-screen border border-r-2 border-slate-200 pt-10">
                <div className="flex pl-8 gap-4 flex-col">
                    <div>
                        <p className="text-black font-regular text-xs text-left">Hi, <br></br><span className="font-bold text-sm">{fullName ? fullName : ""}</span></p>
                    </div>

                    <div onClick={() => handleSwitch("home")} className={`flex flex-row items-center gap-2 p-2 pr-4 ${activeTab == "home" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-slate-300 font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faHomeAlt}></FontAwesomeIcon>
                        <p>Home</p>
                    </div>
                    <div onClick={() => handleSwitch("transactions")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "transactions" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-slate-300 font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faMoneyBill}></FontAwesomeIcon>
                        <p>Transactions</p>
                    </div>
                    <div onClick={() => handleSwitch("businesses")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "businesses" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-slate-300 font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faBuilding}></FontAwesomeIcon>
                        <p>Businesses</p>
                    </div>
                    <div onClick={() => handleSwitch("customers")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "customers" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-slate-300 font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faUserFriends}></FontAwesomeIcon>
                        <p>Customers</p>
                    </div>
                    <div onClick={() => handleSwitch("paymentLinks")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "paymentLinks" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-slate-300 font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>
                        <p>Payment Links</p>
                    </div>
                    <div onClick={() => handleSwitch("wallet")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "wallet" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-slate-300 font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faBox}></FontAwesomeIcon>
                        <p>Wallets</p>
                    </div>
                    <div onClick={() => handleSwitch("withdrawals")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "withdrawals" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-slate-300 font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faSackDollar}></FontAwesomeIcon>
                        <p>Withdrawals</p>
                    </div>


                    <div onClick={() => handleSwitch("discountCodes")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "discountCodes" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-slate-300 font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faPercentage}></FontAwesomeIcon>
                        <p>Discount Codes</p>
                    </div>



                    <div onClick={() => handleSwitch("settings")} className={`flex flex-row items-center  gap-2 p-2 pr-4 ${activeTab == "settings" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-slate-300 font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faCogs}></FontAwesomeIcon>
                        <p>Settings</p>
                    </div>

                    <div onClick={() => handleLogout()} className={`flex flex-row items-center bottom-1  gap-2 p-2 pr-4 ${activeTab == "" ? "text-sm font-bold text-rouzo-deep-blue bg-white" : "text-slate-300 font-regular text-xs"} hover:text-sm hover:cursor-pointer hover:font-bold hover:text-rouzo-deep-blue hover:bg-white rounded-l-xl`}>
                        <FontAwesomeIcon icon={faDoorClosed}></FontAwesomeIcon>
                        <p>Logout</p>
                    </div>

                </div>
            </div>


            {/*Dashboard Home*/}
            {activeTab == "home" ?
                <div className="flex gap-4 flex-col grow p-4 pt-2 md:pt-10 ">
                    <p className="text-black  text-left text-2xl">Platform Summary</p>

                    <div className="flex flex-row gap-4">

                        {/*Transactions Summary*/}
                        <div className="rounded-md p-4 bg-slate-100 drop-shadow-sm flex flex-col gap-2">
                            <div className="flex flex-row gap-4 items-center">
                                <div className="rounded-full p-2 circle bg-slate-200">
                                    <FontAwesomeIcon className="text-slate-400" icon={faMoneyBillWave}></FontAwesomeIcon>
                                </div>
                                <div className="text-left flex flex-col">
                                    <p className="font-bold text-[30px]">{Constants.nairaString}{platformSummary2 ? platformSummary2?.transactions?.success?.totalAmount.toLocaleString() : 0}</p>
                                    <p className="text-xs text-left">Total transaction value</p>
                                </div>
                            </div>
                            <hr className="text-slate-400 bg "></hr>
                            <div className="flex flex-row gap-2 justify-around">
                                <div className="flex flex-col gap-0">
                                    <p className="text-sm text-rouzo-green-light font-bold">{platformSummary2 ? platformSummary2?.transactions?.success?.count.toLocaleString() : 0}/{platformSummary2 ? platformSummary2?.transactions?.total.toLocaleString() : 0}</p>
                                    <p className="text-[9px]">success</p>
                                </div>
                                <div className="flex flex-col gap-0">
                                    <p className="text-sm text-red-400 font-bold">{platformSummary2 ? platformSummary2?.transactions?.failed?.count.toLocaleString() : 0}/{platformSummary2 ? platformSummary2?.transactions?.total.toLocaleString() : 0}</p>
                                    <p className="text-[9px]">failed</p>
                                </div>
                                <div className="flex flex-col gap-0">
                                    <p className="text-sm text-slate-700 font-bold">{platformSummary2 ? platformSummary2?.transactions?.pending?.count.toLocaleString() : 0}/{platformSummary2 ? platformSummary2?.transactions?.total.toLocaleString() : 0}</p>
                                    <p className="text-[9px]">pending</p>
                                </div>
                            </div>
                        </div>

                        {/*Businesses Summary*/}
                        <div className="rounded-md p-4 bg-slate-100 drop-shadow-sm flex flex-col gap-2">
                            <div className="flex flex-row gap-4 items-center">
                                <div className="rounded-full p-2 circle bg-slate-200">
                                    <FontAwesomeIcon className="text-slate-400" icon={faBuilding}>5</FontAwesomeIcon>
                                </div>
                                <div className="text-left flex flex-col">
                                    <p className="font-bold text-[30px]">{platformSummary2 ? platformSummary2.businesses.total.toLocaleString() : 0}</p>
                                    <p className="text-xs text-left">Businesses onboarded</p>
                                </div>
                            </div>
                            <hr className="text-slate-400 bg "></hr>
                            <div className="flex flex-row gap-2 justify-around">
                                <div className="flex flex-col gap-0">
                                    <p className="text-sm text-rouzo-green-light font-bold">{platformSummary2 ? platformSummary2.businesses?.verified?.count.toLocaleString() : 0}/ {platformSummary2 ? platformSummary2?.businesses?.total.toLocaleString() : 0}</p>
                                    <p className="text-[9px]">verified</p>
                                </div>

                                <div className="flex flex-col gap-0">
                                    <p className="text-sm text-slate-700 font-bold">{platformSummary2 ? platformSummary2.businesses?.notVerified?.count.toLocaleString() : 0}/ {platformSummary2 ? platformSummary2?.businesses?.total.toLocaleString() : 0} </p>
                                    <p className="text-[9px]">pending</p>
                                </div>
                            </div>
                        </div>

                        {/*Customers Summary*/}
                        <div className="rounded-md p-4 bg-slate-100 drop-shadow-sm flex flex-col gap-2">
                            <div className="flex flex-row gap-4 items-center">
                                <div className="rounded-full p-2 circle bg-slate-200">
                                    <FontAwesomeIcon className="text-slate-400" icon={faUserGroup}>5</FontAwesomeIcon>
                                </div>
                                <div className="text-left flex flex-col">
                                    <p className="font-bold text-[30px]">{platformSummary2 ? platformSummary2?.customers?.notVerified.count.toLocaleString() : 0}</p>
                                    <p className="text-xs text-left">Customers onboarded</p>
                                </div>
                            </div>
                            {/*<hr className="text-slate-400 bg "></hr>
                            <div className="flex flex-row gap-2 justify-around">
                                <div className="flex flex-col gap-0">
                                    <p className="text-sm text-rouzo-green-light font-bold">2/5</p>
                                    <p className="text-[9px]">verified</p>
                                </div>

                                <div className="flex flex-col gap-0">
                                    <p className="text-sm text-slate-700 font-bold">2/5</p>
                                    <p className="text-[9px]">pending</p>
                                </div>
                            </div>*/}
                        </div>
                    </div>

                    <div className="flex flex-row gap-4">
                        <div className="grow rounded-md bg-slate-100 drop-shadow-sm flex flex-col gap-2">
                            <p className="font-semibold text-sm text-left p-4 pb-2">Transaction Record</p>
                            <hr className="bg-slate-400 px-2"></hr>
                            <div className="flex flex-row justify-end">
                                <div className=" outline outline-1 outline-slate-200/30 rounded-l-md items-center flex flex-row">
                                    <div className="px-2 bg-rouzo-base-color font-bold rounded-l-md text-white text-xs">
                                        <p>Month</p>
                                    </div>
                                    <div className="px-2 text-rouzo-base-color bg-slate-200 font-bold text-xs">
                                        <p>Year</p>
                                    </div>
                                </div>

                            </div>
                            <div>
                                <canvas id="acquisitions"></canvas>

                            </div>
                            <hr className="bg-slate-400 px-2"></hr>
                            <div className="flex flex-row gap-4 p-4">
                                <div className="outline outline-slate-300 rounded-sm p-2 text-left">
                                    <p className="text-xs text-rouzo-deep-blue text-left font-semibold">Revenue</p>
                                    <p className="font-bold text-black text-md">{Constants.nairaString}{platformSummary2 ? platformSummary2.payment?.success?.totalAmount.toLocaleString() : 0}</p>
                                    <hr className="bg-rouzo-base-color px-4"></hr>

                                </div>
                                <div className="outline outline-slate-300 rounded-sm p-2 text-left">
                                    <p className="text-xs text-rouzo-green-light text-left font-semibold">Profit</p>
                                    <p className="font-bold text-black text-md">{Constants.nairaString}{platformSummary2 ? platformSummary2?.['transaction-fees']?.success?.totalAmount.toLocaleString() : 0} </p>
                                    <hr className="bg-rouzo-base-color px-4"></hr>

                                </div>
                                <div className="outline outline-slate-300 rounded-sm p-2 text-left">
                                    <p className="text-xs text-rouzo-green-light text-left font-semibold">Withdrawal</p>
                                    <p className="font-bold text-black text-md">{Constants.nairaString}{platformSummary2 ? platformSummary2?.['withdrawal']?.success?.totalAmount.toLocaleString() : 0} </p>
                                    <hr className="bg-rouzo-base-color px-4"></hr>

                                </div>

                            </div>

                        </div>
                        <div className="flex flex-col rounded-md">
                            <div className="p-4 rounded-t-md bg-rouzo-base-color/60">
                                <div className="flex flex-row justify-between gap-10 items-start">
                                    <div className="text-white text-left">
                                        <p className="font-bold text-md text-[30px] text-white">0</p>
                                        <p className="text-slate-300 text-xs">Total Subscriptions</p>
                                    </div>
                                    <div >
                                        <p className="rounded-md bg-white p-1 text-rouzo-base-color/50 font-bold text-xs">0%</p>
                                    </div>
                                </div>

                            </div>
                            <div className="">

                            </div>
                        </div>
                    </div>
                </div>
                : ""
            }
            {/*Dashboard Wallet*/}
            {activeTab == "wallet" ?
                <div className="flex gap-4 flex-col grow p-4 pt-2 md:pt-10 ">
                    <p className="text-slate-600  text-left text-md ">
                        <span onClick={() => handleSwitch("home")} className="underline underline-offset-1 ">Home</span> / Wallets
                    </p>
                    <WalletsPage></WalletsPage>
                </div>
                : ""
            }
            {/*Dashboard Customers*/}
            {activeTab == "customers" ?
                <div className="flex gap-4 flex-col grow p-4 pt-2 md:pt-10 ">
                    <p className="text-slate-600  text-left text-md ">
                        <span onClick={() => handleSwitch("home")} className="underline underline-offset-1 ">Home</span> / Customers
                    </p>
                    <CustomerPage></CustomerPage>

                </div>
                : ""
            }
            {/*Dashboard Businesses*/}
            {activeTab == "businesses" ?
                <div className="flex gap-4 flex-col grow p-4 pt-2 md:pt-10 ">
                    <p className="text-slate-600  text-left text-md ">
                        <span onClick={() => handleSwitch("home")} className="underline underline-offset-1 ">Home</span> / Businesses
                    </p>
                    <BusinessPage></BusinessPage>

                </div>
                : ""
            }
            {/*TRansactions */}
            {activeTab == "transactions" ?
                <div className="flex gap-4 flex-col grow p-4 pt-2 md:pt-10 ">
                    <p className="text-slate-600  text-left text-md ">
                        <span onClick={() => handleSwitch("home")} className="underline underline-offset-1 ">Home</span> / Transactions
                    </p>
                    <TransactionsPage></TransactionsPage>

                </div>
                : ""
            }
            {/*Withdrawals */}
            {activeTab == "withdrawals" ?
                <div className="flex gap-4 flex-col grow p-4 pt-2 md:pt-10 ">
                    <p className="text-slate-600  text-left text-md ">
                        <span onClick={() => handleSwitch("home")} className="underline underline-offset-1 ">Home</span> / Withdrawals
                    </p>

                    <WithdrawalPage></WithdrawalPage>
                </div>
                : ""
            }
            {/*Payment Links*/}
            {activeTab == "paymentLinks" ?
                <div className="flex gap-4 flex-col grow p-4 pt-2 md:pt-10 ">
                    <p className="text-slate-600  text-left text-md ">
                        <span onClick={() => handleSwitch("home")} className="underline underline-offset-1 ">Home</span> / Payment Links
                    </p>

                </div>
                : ""
            }
            {/*Dsicount Codes*/}
            {activeTab == "discountCodes" ?
                <div className="flex gap-4 flex-col grow p-4 pt-2 md:pt-10 ">
                    <p className="text-slate-600  text-left text-md ">
                        <span onClick={() => handleSwitch("home")} className="underline underline-offset-1 ">Home</span> / Discount Codes
                    </p>

                    <DiscountCodePage></DiscountCodePage>

                </div>
                : ""
            }
            {/*Settings*/}
            {activeTab == "settings" ?
                <div className="flex gap-4 flex-col grow p-4 pt-2 md:pt-10 ">
                    <p className="text-slate-600  text-left text-md ">
                        <span onClick={() => handleSwitch("home")} className="underline underline-offset-1 ">Home</span> / Settings
                    </p>
                </div>
                : ""
            }

            {/****************************************/}
            {/*other SCREEN PAGE*/}
            {/****************************************/}

        </div>
    </div >
}


export default DashboardPage;