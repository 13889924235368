

let CustomerSelectComponent = ({data, disabled,selected, onChange}) => {
    //console.log(data);
    return <>
        <div className="mulish-font">
            <select onChange={onChange} disabled={disabled ? true : false} className="py-2 w-full px-[25px] text-black bg-rouzo-blue-light rounded-xl">
                <option className="lota-font text-base " value={null}>None</option>
                {data ? data.map(d => {
                    return <option className="lota-font text-base " value={d.customerId}>{d.emailAddress}</option>
                }) : ""}
            </select>
        </div>
    </>
}

export default CustomerSelectComponent;