

import { useState, useEffect, useContext } from "react";

import useAccounts from "../../hooks/useAccount";
import { AccountContext } from "../../context/AccountContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faCheckCircle, faCirclePause } from "@fortawesome/free-regular-svg-icons";

let BusinessPage = () => {

    let { businesses } = useContext(AccountContext);
    let { fetchUsers } = useAccounts();

    useEffect(() => {
        if (!businesses) {
            fetchUsers()
        }
    })
    return <>
        <div className="flex flex-row gap-4">
            <div className="flex flex-col grow gap-2">
                <div className="flex flex-row outline outline-slate-200 rounded-[2px] gap-2">
                    <input></input>
                    <div className="flex flex-row">

                    </div>
                </div>
                <div className="flex flex-row gap-2">
                    <div className="flex flex-col gap-2 grow ">
                        <p className="text-left text-md font-semibold">Businesses</p>
                        <div className="grid grid-cols-2 gap-2">
                            {
                                businesses && businesses.length > 0 ?
                                    businesses.map(business => {
                                        return <div className="flex flex-row justify-between gap-4 p-4 outline outline-slate-200 rounded-md bg-slate-100 shadow-sm">
                                            <div className="flex flex-col gap-1">
                                                <div className="flex flex-row gap-2 items-center">
                                                    <p className="font-semibold text-md">{business?.businessName ? business?.businessName : business.fullName}</p>
                                                    {
                                                        business.emailVerified ?
                                                            <FontAwesomeIcon className="rounded-full text-white bg-rouzo-green-light" icon={faCheckCircle}></FontAwesomeIcon>
                                                            :
                                                            <FontAwesomeIcon icon={faCirclePause}></FontAwesomeIcon>
                                                    }
                                                </div>
                                                <i className="text-left text-[8px] ">Joined : {new Date(business.createdAt).toDateString()} </i>
                                            </div>
                                            <div>

                                            </div>
                                        </div>
                                    })
                                    : <div className="flex flex-row items-center text-center p-4 rounded-md h-20 bg-slate-200">
                                        <p>No businesses yet</p>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 px-2">
                        <div className="flex flex-row gap-2">
                            <div className="rounded-md p-4 bg-slate-100 drop-shadow-sm flex flex-col gap-2 ">
                                <div className="flex flex-row gap-4 items-center">
                                    <div className="rounded-full p-2 circle bg-slate-200">
                                        <FontAwesomeIcon className="text-slate-400" icon={faBuilding}></FontAwesomeIcon>
                                    </div>
                                    <div className="text-left flex flex-col">
                                        <p className="font-bold text-[30px]">{businesses ? businesses.length : 0}</p>
                                        <p className="text-xs text-left">Business(es) onboarded</p>
                                    </div>
                                </div>

                                <hr></hr>
                                <div className="flex flex-row gap-2 justify-around">
                                    <div className="flex flex-col  gap-0">
                                        <p className="text-sm text-rouzo-green-light font-bold">{businesses ? businesses.reduce((a,b) => (b.emailVerified == true) ? a + 1 : a + 0,0) : 0}/{businesses ? businesses.length.toLocaleString() : 0}</p>
                                        <p className="text-[9px]">verified</p>
                                    </div>
                                    <div className="flex flex-col  gap-0">
                                        <p className="text-sm text-slate-400 font-bold">{businesses ? businesses.reduce((a,b) => (b.emailVerified == false) ? a + 1 : a + 0,0) : 0}/{businesses ? businesses.length.toLocaleString() : 0}</p>
                                        <p className="text-[9px]">pending</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <div className="flex flex-col gap-4">

            </div>

        </div>

    </>
}



export default BusinessPage;