
import { AccountContext } from "../../context/AccountContext";
import TransactionService from "../../api/services/TransactionService";
import ModalRootComponent from "./ModalRootComponent";
import CTAButtonComponent from "../CTAButtonComponent";
import InputComponent from "../InputComponent";
import { toast } from "react-toastify";
import { useState, useContext, useEffect } from "react";
import useAccounts from "../../hooks/useAccount";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faInfoCircle, faLink, faSpinner } from "@fortawesome/free-solid-svg-icons";
import PaymentLinkService from "../../api/services/PaymentLinkService";
import DiscountCodeService from "../../api/services/DiscountCodeService";
import SelectComponent3 from "../SelectComponent3";
import CustomerSelectComponent from "../CustomerSelectComponent";

let NewDiscountCodeComponent = ({ setIsModalActive }) => {
    const navigate = useNavigate();

    let { customers } = useContext(AccountContext)

    let [emailAddress, setEmailAddress] = useState(null);
    //let []
    let [percentage, setPercentage] = useState(0);
    let [description, setDescription] = useState(null);
    let [discountCodeEmailAddressRecipient, setDiscountCodeEmailAddressRecipient] = useState(null);
    let [discountCodeCustomerIdRecipient, setDiscountCodeCustomerIdRecipient] = useState(null);
    let [customCode, setCustomCode] = useState(null);
    let [useCustomCode, setUseCustomCode] = useState(false);

    let { fetchDiscountCodes } = useAccounts();

    let [errorMsg, setErrorMsg] = useState("");

    let [isCreatingLink, setIsCreatingLink] = useState(false);
    let [generatedLink, setGeneratedLink] = useState(null);

    useEffect(() => {


    })

    let handleNewDiscountCode = async () => {
        if (isCreatingLink) {
            return;
        }
        setIsCreatingLink(true);

        if(!/^[0-9a-zA-Z-]+$/.test(customCode)){
            setIsCreatingLink(false);
            toast.error("Custom discount code is invalid. Ensure there are no spaces. \n Allowed characters  : -");
            return;
        }
        if (!isNaN(percentage)) {
            if (percentage <= 0) {
                setIsCreatingLink(false);
                toast.error("Invalid discount percentage");
                return;
            }
            if (percentage > 100) {
                setIsCreatingLink(false);
                toast.error("Invalid discount percentage");
                return;
            }
        }

        try {
            let newlink = await DiscountCodeService.new(
                description,
                percentage,
                discountCodeEmailAddressRecipient,
                discountCodeCustomerIdRecipient,
                customCode
            );

            if (newlink.success) {
                toast.success(newlink.msg);
                fetchDiscountCodes();
                console.log(newlink);
            }
            else {
                toast.error(newlink.msg)
            }
        }
        catch (e) {
            console.log(e);
        }

        setIsCreatingLink(false)


    }
    return <ModalRootComponent
        children={
            <div className="z20 font-lota flex flex-col lota-font rounded-t-md border border-t-2 border-t-rouzo-green-light self-center bg-white">
                <div className="flex flex-row justify-end text-right p-1 pb-0 hover:cursor-pointer hover:text-xl">
                    <p onClick={() => setIsModalActive(false)} className="">
                        <FontAwesomeIcon className="text-md" icon={faClose}></FontAwesomeIcon>
                    </p>
                </div>
                <div className="flex flex-col p-6 pt-1 gap-2">
                    <div className="text-center">
                        <p className="font-bold text-md text-rouzo-green-light">Generate Discount Coupon</p>
                    </div>
                    <div className="flex flex-col  gap-2 ">
                        <div className="md:flex-row flex-col flex gap-2 ">
                            <div className="flex flex-col">
                                <p className="text-xs text-center md:text-left">Description</p>
                                <div className="flex flex-row md:justify-start justify-center">
                                    <p className="text-orange-400 bg-orange-50 text-[8px] rounded-md px-1 flex flex-row gap-1 items-center">
                                        <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                                        Describe your discout code
                                    </p>
                                </div>
                                {isCreatingLink ? <InputComponent disabled={true} value={description} ></InputComponent> : <InputComponent onChange={(e) => setDescription(e.target.value)}></InputComponent>}
                            </div>
                            <div className="flex flex-col">
                                <div className="flex flex-row gap-1 justify-center md:justify-start" >
                                    <input onChange = {(e) => setUseCustomCode(e.target.checked)} type="checkbox"></input>
                                    <p className="text-xs text-center md:text-left">Use Custom Code</p>
                                </div>
                                <div className="flex flex-row md:justify-start justify-center">
                                    <p className="text-orange-400 bg-orange-50 text-[8px] rounded-md px-1 flex flex-row gap-1 items-center">
                                        <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                                        Use a custom discount code e.g XMAS20-OFF
                                    </p>
                                </div>
                                {(isCreatingLink || useCustomCode == false) ? <InputComponent disabled={true} value={customCode} ></InputComponent> : <InputComponent onChange={(e) => setCustomCode(e.target.value)}></InputComponent>}
                            </div>
                            <div className="flex flex-col">
                                <p className="text-xs text-center  md:text-left">Percentage</p>
                                <div className="flex flex-row justify-center md:justify-start">
                                    <p className="text-orange-400 bg-orange-50 text-[8px] rounded-md px-1 flex flex-row gap-1 items-center">
                                        <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                                        Discount at which customer will pay at
                                    </p>
                                </div>
                                {isCreatingLink ? <InputComponent disabled={true} value={percentage}></InputComponent> : <InputComponent type="number" onChange={(e) => setPercentage(e.target.value)}></InputComponent>}
                            </div>
                        </div>

                        <hr>
                        </hr>
                        <div className="">
                            <p className="text-left font-bold text-xs">Send To</p>
                            <div className="flex flex-row">
                                <div className="flex flex-col gap-1">
                                    <p className="text-xs">Customer</p>
                                    <CustomerSelectComponent onChange={(e) => setDiscountCodeCustomerIdRecipient(e.target.value)} data={customers}></CustomerSelectComponent>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <p className="text-xs">Email address</p>
                                    <InputComponent onChange={(e) => setDiscountCodeEmailAddressRecipient(e.target.value)}></InputComponent>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row justify-end">
                            <div onClick={() => handleNewDiscountCode()} className={`flex flex-row text-white rounded-md p-2 gap-2 ${isCreatingLink ? "bg-green-800" : "bg-rouzo-green-light  hover:border-2 hover:cursor-pointer hover:border-rouzo-green-light hover:text-rouzo-green-light hover:bg-white"}`}>
                                {isCreatingLink ? <FontAwesomeIcon spin={true} icon={faSpinner}></FontAwesomeIcon> : <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>}
                                <p className="text-xs text-left font-bold ">{isCreatingLink ? "Generating.." : "Generate"}</p>

                            </div>
                        </div>

                        <div className="">

                        </div>
                    </div>
                </div>

            </div>
        }
    >

    </ModalRootComponent>
}


export default NewDiscountCodeComponent;