import TransactionPageListComponent from "./TransactionPageListComponent";
import { useState, useEffect, useContext } from "react";

import useAccounts from "../../hooks/useAccount";
import { AccountContext } from "../../context/AccountContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareFromSquare } from "@fortawesome/free-regular-svg-icons";
import { faArrowDown19, faArrowUp19, faCirclePlus, faInbox } from "@fortawesome/free-solid-svg-icons";
import Constants from "../../constants/Constants";


let TransactionsPage = () => {



    let { transactions, platformSummary2 } = useContext(AccountContext);
    let { fetchTransactions } = useAccounts();

    return <>
        <div className="flex flex-row gap-4">
            <div className="flex flex-col ">
                <div className="flex flex-row gap-2">
                    <input></input>
                    <div className="flex flex-row">

                    </div>
                </div>
                <div className="flex flex-col gap-4 grow">
                    <div className="flex flex-row gap-2 ">

                        <div className="rounded-md p-4 bg-slate-100 drop-shadow-sm flex flex-col gap-2 ">
                            <div className="flex flex-row gap-4 items-center">
                                <div className="rounded-full p-2 circle bg-slate-200">
                                    <FontAwesomeIcon className="text-slate-400" icon={faCirclePlus}></FontAwesomeIcon>
                                </div>
                                <div className="text-left flex flex-col">
                                    <p className="font-bold text-[30px]">{Constants.nairaString}{platformSummary2 ? platformSummary2?.transactions?.success?.totalAmount.toLocaleString() : 0}</p>
                                    <p className="text-xs text-left">Transaction Value Generated</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="flex flex-row justify-around gap-0">
                                <div className="flex flex-col gap-0">
                                    <p className="text-sm text-rouzo-green-light font-bold">{platformSummary2 ? platformSummary2?.transactions?.success?.count.toLocaleString() : 0}/{platformSummary2 ? platformSummary2?.transactions?.total.toLocaleString() : 0}</p>
                                    <p className="text-[9px]">success</p>
                                </div>
                                <div className="flex flex-col gap-0">
                                    <p className="text-sm text-red-400 font-bold">{platformSummary2 ? platformSummary2?.transactions?.failed?.count.toLocaleString() : 0}/{platformSummary2 ? platformSummary2?.transactions?.total.toLocaleString() : 0}</p>
                                    <p className="text-[9px]">failed</p>
                                </div>
                                <div className="flex flex-col gap-0">
                                    <p className="text-sm text-slate-700 font-bold">{platformSummary2 ? platformSummary2?.transactions?.pending?.count.toLocaleString() : 0}/{platformSummary2 ? platformSummary2?.transactions?.total.toLocaleString() : 0}</p>
                                    <p className="text-[9px]">pending</p>
                                </div>
                            </div>
                        </div>


                        <div className="rounded-md p-4 bg-slate-100 drop-shadow-sm flex flex-col gap-2 ">
                            <div className="flex flex-row gap-4 items-center">
                                <div className="rounded-full p-2 circle bg-slate-200">
                                    <FontAwesomeIcon className="text-slate-400" icon={faArrowDown19}></FontAwesomeIcon>
                                </div>
                                <div className="text-left flex flex-col">
                                    <p className="font-bold text-[20px]">{Constants.nairaString}{platformSummary2 ? platformSummary2?.transactionsByType?.credit?.success?.totalAmount.toLocaleString() : 0}</p>
                                    <p className="text-xs text-left">Total Credit Inflow</p>
                                </div>

                            </div>
                            <hr></hr>
                            <div className="flex flex-row gap-4 items-center">
                                <div className="rounded-full p-2 circle bg-slate-200">
                                    <FontAwesomeIcon className="text-slate-400" icon={faArrowUp19}></FontAwesomeIcon>
                                </div>
                                <div className="text-left flex flex-col">
                                    <p className="font-bold text-[20px]">{Constants.nairaString}{platformSummary2 ? platformSummary2?.transactionsByType?.debit?.success?.totalAmount.toLocaleString() : 0}</p>
                                    <p className="text-xs text-left">Total Debit Outflow</p>
                                </div>

                            </div>
                        </div>


                    </div>
                    <div>
                        <p className="text-left text-md font-semibold">Transaction Details</p>
                        <div className="flex flex-col gap-2 ">
                            <TransactionPageListComponent data={transactions}></TransactionPageListComponent>
                        </div>
                    </div>

                </div>

            </div>


        </div>

    </>
}


export default TransactionsPage;