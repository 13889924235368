




import { useState, useEffect, useContext } from "react";

import useAccounts from "../../hooks/useAccount";
import { AccountContext } from "../../context/AccountContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { faUserGroup, faPercentage } from "@fortawesome/free-solid-svg-icons";

let DiscountCodePage = () => {

    let { discountCodes } = useContext(AccountContext);
    let { fetchDiscountCodes } = useAccounts();

    useEffect(() => {
        if (!discountCodes) {
            fetchDiscountCodes()
        }
    })
    return <>
        <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-4 ">
                <div className="flex flex-row outline outline-slate-200 rounded-[2px] gap-2">
                    <input></input>
                    <div className="flex flex-row">

                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <p className="text-left text-md font-semibold">Discount Codes</p>
                    <div className="flex flex-col gap-4 overflow-y-auto max-h-[500px] min-h-32">
                        {
                            discountCodes && discountCodes.length > 0 ?
                                discountCodes.map(discountCode => {
                                    return <div className={`flex items-center flex-row gap-4 p-4 outline rounded-md ${discountCode.used == false ? "bg-emerald-200 outline-emerald-200" : "bg-slate-400 outline-slate-200"} shadow-sm`}>
                                        <div className="flex flex-row gap-2 items-center">
                                            <div className="flex flex-col text-left">
                                                <p className="text-md font-thin">{discountCode.description}</p>
                                                <p className="text-xs items-center flex flex-row font-regular ">
                                                    <p className="bg-slate-50 px-1">
                                                        {discountCode.discountCode}
                                                    </p>
                                                    <div className="rounded-r-md px-1 font-bold text-xs text-white bg-rouzo-green-light">
                                                        {discountCode.percentageSlug}
                                                    </div>
                                                </p>
                                            </div>


                                        </div>
                                    </div>
                                })
                                : <div className="flex flex-row items-center h-20 bg-slate-300">
                                    <p>No Discount Codes yet</p>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-2 px-2">
                <div className="flex flex-row gap-2">
                    <div className="rounded-md p-4 bg-slate-100 drop-shadow-sm flex flex-col gap-2 ">
                        <div className="flex flex-row gap-4 items-center">
                            <div className="rounded-full p-2 circle bg-slate-200">
                                <FontAwesomeIcon className="text-slate-400" icon={faPercentage}></FontAwesomeIcon>
                            </div>
                            <div className="text-left flex flex-col">
                                <p className="font-bold text-[30px]">{discountCodes ? discountCodes.length : 0}</p>
                                <p className="text-xs text-left">Discount Codes Created</p>
                            </div>
                        </div>
                        <hr></hr>
                        <div>

                        </div>
                    </div>

                </div>

            </div>

        </div>

    </>
}



export default DiscountCodePage;