

import { useState, useEffect, useContext } from "react";

import useAccounts from "../../hooks/useAccount";
import { AccountContext } from "../../context/AccountContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";

let CustomerPage = () => {

    let { customers } = useContext(AccountContext);
    let { fetchCustomers } = useAccounts();

    useEffect(() => {
        if (!customers) {
            fetchCustomers()
        }
    })
    return <>
        <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-4 ">
                <div className="flex flex-row outline outline-slate-200 rounded-[2px] gap-2">
                    <input></input>
                    <div className="flex flex-row">

                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <p className="text-left text-md font-semibold">Customers</p>
                    <div className="flex flex-col gap-4">
                        {
                            customers && customers.length > 0 ?
                                customers.map(customer => {
                                    return <div className="flex items-center flex-row gap-4 p-4 rounded-[2px] bg-slate-200 shadow-sm">
                                        <p className="text-md font-bold ">{customer.fullName}</p>
                                        <Link to={`mailto:${customer.emailAddress ? customer.emailAddress : ""}`}>
                                            <p className="text-xs ">{customer.emailAddress}</p>
                                        </Link>
                                        <p>{customer.mobileNumber}</p>
                                    </div>
                                })
                                : <div className="flex flex-row items-center h-20 bg-slate-300">
                                    <p>No customers yet</p>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-2 px-2">
                <div className="flex flex-row gap-2">
                    <div className="rounded-md p-4 bg-slate-100 drop-shadow-sm flex flex-col gap-2 ">
                        <div className="flex flex-row gap-4 items-center">
                            <div className="rounded-full p-2 circle bg-slate-200">
                                <FontAwesomeIcon className="text-slate-400" icon={faUserGroup}></FontAwesomeIcon>
                            </div>
                            <div className="text-left flex flex-col">
                                <p className="font-bold text-[30px]">{customers ? customers.length.toLocaleString() : 0}</p>
                                <p className="text-xs text-left">Customers onboarded</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </>
}



export default CustomerPage;