import { useContext, useState } from "react";
import { OnboardContext } from "../../context/OnboardContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faNavicon } from "@fortawesome/free-solid-svg-icons";


let TopNavComponent = ({ setPage,setSidebarActive, isSidebarActive }) => {
    let { isLoggedIn } = useContext(OnboardContext)
    return <>
        <div className="px-4 hidden md:flex flex-row  fixed top-0 bg-white w-full py-4 justify-between shadow-sm shadow-rouzo-deep-blue/10">
            <div className="">
                <Link to={"/"}>
                    <p className="text-rouzo-deep-blue font-bold text-2xl">Boost</p>
                </Link>
            </div>
            <div className="text-[15px] text-rouzo-deep-blue font-regular flex flex-row gap-2 items-center">
                <div className="hover:rouzo-base-color hover:cursor-pointer hover:underline hover:underline-offset-2">
                    <p onClick={() => setPage(1)}>Rewards & Loyalty</p>

                </div>
                <div className="hover:rouzo-base-color hover:cursor-pointer hover:underline hover:underline-offset-2">
                    <p  onClick={() => setPage(2)}>Payment Links</p>

                </div>
                <div className="hover:rouzo-base-color hover:cursor-pointer hover:underline hover:underline-offset-2">
                    <p>Contact Us</p>
                </div>
                <div className="ml-6">
                    {
                        isLoggedIn ?
                            <div className="flex flex-row gap-2 items-center">
                                <Link to={"/dashboard"}>
                                    <p className="outline outline-1 outline-white text-white font-thin p-2 text-sm bg-rouzo-base-color">Dashboard</p>
                                </Link>
                            </div>
                            :
                            <div className="flex flex-row gap-2 items-center">
                                <Link to={"/login"}>
                                    <p className="hover:text-rouzo-base-color hover:bg-blue-300 hover:font-bold text-white px-4 py-2 bg-rouzo-base-color rounded-md font-regular text-sm  ">Login</p>
                                </Link>
                                <Link to={"/onboard"}>
                                    <p className="hover:text-white hover:font-bold text-black bg-rouzo-green-light  px-4 py-2 rounded-md font-regular text-sm ">Sign up</p>
                                </Link>
                            </div>
                    }
                </div>
            </div>
        </div>
        <div className="flex md:hidden fixed w-full p-4 flex-row justify-between">
            <Link to={"/"}>
                <p className="font-bold text-white text-2xl">Boost</p>
            </Link>
            <p>
                {isSidebarActive ?
                    <FontAwesomeIcon className="text-xl text-white" icon={faClose}></FontAwesomeIcon>
                    :
                    <FontAwesomeIcon onClick={() => setSidebarActive(true)} className="text-xl text-white" icon={faNavicon}></FontAwesomeIcon>
                }

            </p>
        </div>
    </>

}


export default TopNavComponent;