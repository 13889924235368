import TopNavComponent from "../../components/home/TopNavComponent";


import UserSvg from "../../assets/user-svgrepo-com.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import HomePageSidebarComponent from "../../components/HomePageSidebarComponent";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import HomeCardSvgComponent from "../../components/HomeCardSvgComponent";
import RecurSvg from "../../assets/pie-chart-svgrepo-com.svg"
import BoostSvg from "../../assets/line-graph-svgrepo-com.svg"
import HonorSvg from "../../assets/honor-svgrepo-com.svg";
import PaymentSvg from "../../assets/img/svg/money-recive.svg";
import SecuritySvg from "../../assets/safe-and-stable-svgrepo-com.svg";
import PromoModal from "../../components/modals/PromoModal";
import SurveyModal from "../../components/modals/SurveyModal";


let LandingPage = ({promo}) => {
    let [isSidebarActive, setSidebarActive] = useState(false);
 
    let [promoActive, setPromoActive] = useState(true);
    let [page, setPage] = useState(0);

    let handleScrolling = async () => {
        console.log("scroll")
        console.log(page)
        if (page != 2) {
            page = setPage(page + 1);
        }



    }
    return <>
        {/*<div style={{backgroundImage: `linear-gradient(to right top, #051937, #002265, #002993, #002ac1, #121ceb)`  }} className="h-screen w-screen">
            <TopNavComponent></TopNavComponent>
        </div>*/}
        <SurveyModal></SurveyModal>
        {(promo == true && promoActive == true)? <PromoModal setActive={setPromoActive}></PromoModal> : ""}
        {isSidebarActive ? <HomePageSidebarComponent setPage={setPage} setSidebarActive={setSidebarActive}></HomePageSidebarComponent> : ""}
        <div className="h-screen md:bg-white bg-rouzo-base-color w-screen">
            <TopNavComponent setPage={setPage} isSidebarActive={isSidebarActive} setSidebarActive={setSidebarActive}></TopNavComponent>
            <div className="md:flex hidden pt-52 px-20 md:bottom-1 h-full ">
                {page == 0 ?
                    <div style={{ backgroundImage: `linear-gradient(to right top, #051937, #002265, #002993, #002ac1, #121ceb)` }} className="h-full w-full flex flex-col px-16 pt-16 text-center gap-16 rounded-t-3xl">
                        <div className="flex flex-row items-start justify-start">
                            <HomeCardSvgComponent interval={2000} imgs={[UserSvg, RecurSvg, BoostSvg]}></HomeCardSvgComponent>

                            <div className="grow-1">

                            </div>
                        </div>
                        <div className="flex flex-col  items-center justify-center">
                            <div className="flex flex-row">
                                <div>

                                </div>
                                <div className="flex flex-col gap-8 ">
                                    <p className="font-regular text-[80px] leading-[50px] pl-8 text-white text-right grow-1 ">
                                        Turn one time transactions into life time customers
                                        <p className="flex flex-row text-sm text-right font-bold justify-end mt-8">Loyalty Rewards * Discount Coupons * Campaigns * Payment Links  </p>
                                    </p>

                                    <div className="flex flex-row justify-end">

                                        <Link to={"https://boost.rouzo.co/onboard"}>
                                            <p className="px-2  py-1 text-[20px] bg-rouzo-green-light font-bold text-white rounded-xl animate-pulse hover:animate-none">How ? &nbsp; <FontAwesomeIcon className="font-regular " icon={faArrowRight}></FontAwesomeIcon> </p>
                                        </Link>
                                    </div>

                                </div>


                            </div>
                        </div>


                    </div>
                    :
                    ""
                }
                {
                    page == 1 ?
                        <div style={{ backgroundImage: `linear-gradient(to right top, #051937, #002265, #002993, #002ac1, #121ceb)` }} className="h-full w-full flex flex-col px-16 pt-16 text-center gap-16 rounded-t-3xl">
                            <div className="flex flex-row items-start justify-start">
                                <HomeCardSvgComponent interval={2000} imgs={[HonorSvg]}></HomeCardSvgComponent>

                                <div className="grow-1">

                                </div>
                            </div>
                            <div className="flex flex-col  items-center justify-center">
                                <div className="flex flex-row">
                                    <div>

                                    </div>
                                    <div className="flex flex-col gap-8 ">
                                        <p className="font-regular text-[80px] leading-[50px] pl-8 text-white text-left grow-1 ">
                                            Reward Loyalty with Giveaways!!
                                            <p className="flex flex-row text-sm text-left font-thin justify-end mt-8">  Show an heartfeeling appreciation to your loyal customers
                                                and also spread the love to potential customers by generating discount codes and rewarding customers per purchase.</p>
                                        </p>

                                        <div className="flex flex-row justify-end">

                                            <Link to={"https://boost.rouzo.co/onboard"}>
                                                <p className="px-2  py-1 text-[20px] bg-rouzo-green-light font-bold text-white rounded-xl animate-pulse hover:animate-none">How ? &nbsp; <FontAwesomeIcon className="font-regular " icon={faArrowRight}></FontAwesomeIcon> </p>
                                            </Link>

                                        </div>

                                    </div>


                                </div>
                            </div>


                        </div>
                        :
                        ""
                }
                {
                    page == 2 ?
                        <div style={{ backgroundImage: `linear-gradient(to right top, #051937, #002265, #002993, #002ac1, #121ceb)` }} className="h-full w-full flex flex-col px-16 pt-16 text-center gap-16 rounded-t-3xl">
                            <div className="flex flex-row items-start justify-start">
                                <HomeCardSvgComponent interval={2000} imgs={[PaymentSvg, SecuritySvg]}></HomeCardSvgComponent>

                                <div className="grow-1">

                                </div>
                            </div>
                            <div className="flex flex-col  items-center justify-center">
                                <div className="flex flex-row">
                                    <div>

                                    </div>
                                    <div className="flex flex-col gap-8 ">
                                        <p className="font-regular text-[80px] leading-[50px] pl-8 text-white text-left grow-1 ">
                                            Effortless Payment Links
                                            <p className="flex flex-row text-sm text-left font-thin justify-end mt-8"> Simply enter an amount and a brief description, and share to your channels. </p>
                                        </p>

                                        <div className="flex flex-row justify-end">

                                            <Link to={"https://boost.rouzo.co/onboard"}>
                                                <p className="px-2  py-1 text-[20px] bg-rouzo-green-light font-bold text-white rounded-xl animate-pulse hover:animate-none">How ? &nbsp; <FontAwesomeIcon className="font-regular " icon={faArrowRight}></FontAwesomeIcon> </p>
                                            </Link>

                                        </div>

                                    </div>


                                </div>
                            </div>


                        </div>
                        :
                        ""
                }


            </div>
            <div onScroll={() => handleScrolling()} className="flex md:hidden px-20 pt-16 md:bottom-1 bg-rouzo-base-color flex flex-col gap-4 h-full items-center justify-center">
                {
                    page == 0 ?
                        <div>
                            <p className="font-regular text-[40px] leading-[30px] pl-8 text-white text-right grow-1 ">
                                Turn one time transactions into life time customers
                                <p className="flex flex-row text-sm text-right font-thin justify-end mt-8">
                                    Loyalty Rewards * Discount Coupons * Campaigns * Payment Links
                                </p>
                                <Link to={"https://boost.rouzo.co/onboard"}>
                                    <p className="px-2 mt-4 py-1 text-sm bg-rouzo-green-light font-bold rounded-xl">How ? &nbsp; <FontAwesomeIcon className="font-regular " icon={faArrowRight}></FontAwesomeIcon> </p>
                                </Link>

                            </p>
                        </div>
                        :
                        ""
                }

                {
                    page == 1 ?
                        <div>
                            <p className="font-regular text-[40px] leading-[30px] pl-8 text-white text-right grow-1 ">
                                Reward Loyalty with Giveaways!!
                                <p className="flex flex-row text-sm text-right font-thin justify-end mt-8">
                                    Show an heartfeeling appreciation to your loyal customers
                                    and also spread the love to potential customers by generating discount codes and rewarding customers per purchase.
                                </p>
                                <Link to={"https://boost.rouzo.co/onboard"}>
                                    <p className="px-2 mt-4 py-1 text-sm bg-rouzo-green-light font-bold rounded-xl">How ? &nbsp; <FontAwesomeIcon className="font-regular " icon={faArrowRight}></FontAwesomeIcon> </p>
                                </Link>

                            </p>
                        </div>
                        :
                        ""
                }
                {
                    page == 2 ?
                        <div>
                            <p className="font-regular text-[40px] leading-[30px] pl-8 text-white text-right grow-1 ">
                                Effortless Payment Links
                                <p className="flex flex-row text-sm text-right font-thin justify-end mt-8">
                                    Simply enter an amount and a brief description, and share to your channels.
                                </p>
                                <Link to={"https://boost.rouzo.co/onboard"}>
                                    <p className="px-2 mt-4 py-1 text-sm bg-rouzo-green-light font-bold rounded-xl">How ? &nbsp; <FontAwesomeIcon className="font-regular " icon={faArrowRight}></FontAwesomeIcon> </p>
                                </Link>

                            </p>
                        </div>
                        :
                        ""
                }

            </div>
            <div onClick={() => handleScrolling()} className="fixed bottom-4 w-full text-center animate-pulse hover:cursor-pointer">
                {
                    (page >= 0 && page < 2) ?
                        <div className="flex flex-col justify-center">
                            <p className="text-white text-xl animate-pulse font-thin">More</p>
                            <FontAwesomeIcon className="text-white " icon={faArrowDown}></FontAwesomeIcon>
                        </div>
                        :
                        ""
                }
                {
                    page == 2 ?
                        <div onClick={() => setPage(0)} className="flex flex-col justify-center">
                            <FontAwesomeIcon className="text-white " icon={faArrowUp}></FontAwesomeIcon>
                            <p className="text-white text-xl animate-pulse font-thin">Top</p>

                        </div>
                        :
                        ""
                }

            </div>
        </div>
    </>
}



export default LandingPage;