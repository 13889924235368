import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Constants from "../../constants/Constants"
import { faArrowAltCircleDown, faArrowAltCircleUp, faArrowDownLong, faArrowUpLong, faCopy } from "@fortawesome/free-solid-svg-icons"




let TransactionPageListComponent = ({ data }) => {
    return <>
        {
            data && data.length > 0 ?
                data.map(transaction => {
                    return <>
                        {/*<div className="rounded-md shadow-sm outline outline-slate-100 p-4">

                </div>*/}
                        <div>
                            {transaction.status == "success" ?
                                <div className="outline  outline-1 rounded-md bg-emerald-300/30 outline-emerald-300">
                                    {/*<div className="flex flex-row justify-end">
                                            <p className="text-xs text-right bg-rouzo-green-light px-2 pt-0 rounded-md text-white font-bold">success</p>
                                        </div>*/}
                                    <div className="flex items-center justify-between flex-row gap-4 px-2 py-4 ">
                                        <div className="flex items-center flex-row gap-4 ">
                                            {
                                                transaction.type == "credit" ?
                                                    <FontAwesomeIcon className="text-rouzo-green-light h-4" icon={faArrowAltCircleDown}></FontAwesomeIcon>
                                                    : ""
                                            }
                                            {
                                                transaction.type == "debit" ?
                                                    <FontAwesomeIcon className="text-red-400 h-4" icon={faArrowAltCircleUp}></FontAwesomeIcon>
                                                    : ""
                                            }
                                            <div className="md:flex hidden  flex-col gap-1">
                                                <p className=" flex flex-row gap-1 items-center font-regular text-[9px] bg-slate-50 px-2 rounded-full">
                                                    {transaction.transactionId}
                                                    <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
                                                </p>
                                                <div className="flex flex-row text-left justify-start">
                                                    <p className="bg-rouzo-base-color text-white text-[8px] py-0 px-2 rounded-full">{transaction.source ? transaction.source : ""}</p>
                                                </div>


                                            </div>
                                            <div className=" flex flex-col gap-1">
                                                <p className="font-regular text-left text-xs">{transaction.emailAddress ? transaction.emailAddress : ""}</p>
                                                <p className="font-bold text-[9px] text-left rounded-full">{transaction.createdAt ? `${new Date(transaction.createdAt).toDateString()} ${new Date(transaction.createdAt).toLocaleTimeString()}` : ""}</p>
                                                <p className=" items-center truncate font-regular text-[9px] bg-slate-50 px-2 rounded-full">
                                                    {transaction.transactionId}
                                                </p>
                                            </div>


                                        </div>
                                        <div>
                                            <div className="flex flex-col gap-2 ">
                                                <p className="rounded-sm bg-white px-2 font-thin text-xs">Revenue</p>
                                                <p className="font-bold text-rouzo-deep-blue rounded-sm bg-white px-2">{Constants.nairaString}{transaction.amount ? transaction.amount.toLocaleString() : 0}</p>
                                            </div>


                                            <div className="md:hidden flex flex-col">
                                                <hr></hr>
                                            </div>
                                        </div>
                                        {
                                            transaction.type == "credit" ?
                                                <>
                                                    <div className="md:flex hidden gap-2 flex-row">
                                                        <div className="flex flex-col gap-2 ">
                                                            <p className="rounded-sm bg-white px-2 font-thin text-xs">Transaction Fees</p>
                                                            <p className="font-bold text-rouzo-deep-blue rounded-sm bg-white px-2">{Constants.nairaString}{transaction.totalFees ? transaction.totalFees.toLocaleString() : 0}</p>
                                                        </div>
                                                        <div className="flex flex-col gap-2 ">
                                                            <p className="rounded-sm bg-white px-2 text-xs font-thin">Settled Amount</p>
                                                            <p className="font-bold text-rouzo-deep-blue rounded-sm bg-white px-2">{Constants.nairaString}{transaction.settledAmount ? transaction.settledAmount.toLocaleString() : 0}</p>
                                                        </div>
                                                    </div>
                                                </>
                                                : ""
                                        }
                                    </div>


                                </div>
                                : ""}
                            {transaction.status == "failed" ?
                                <div className="outline  outline-1 rounded-md  bg-red-50 outline-red-300">
                                    {/*<div className="flex flex-row justify-end">
                                            <p className="text-xs text-right bg-red-400 px-2 pt-0 rounded-md text-white font-bold">failed</p>
                                        </div>*/}
                                    <div className="flex items-center flex-row gap-4 px-2 py-4 justify-between ">
                                        <div className="flex flex items-center flex-row gap-4">

                                            {
                                                transaction.type == "credit" ?
                                                    <FontAwesomeIcon className="text-rouzo-green-light h-4" icon={faArrowAltCircleDown}></FontAwesomeIcon>
                                                    : ""
                                            }
                                            {
                                                transaction.type == "debit" ?
                                                    <FontAwesomeIcon className="text-red-400 h-4" icon={faArrowAltCircleUp}></FontAwesomeIcon>
                                                    : ""
                                            }
                                            <div className="hidden md:flex flex-col gap-1">
                                                <p className=" flex flex-row gap-1 items-center font-regular text-[9px] bg-slate-50 px-2 rounded-full">
                                                    {transaction.transactionId}
                                                    <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
                                                </p>
                                                <div className="flex flex-row text-left justify-start">
                                                    <p className="bg-rouzo-base-color text-white text-[8px] py-0 px-2 rounded-full">{transaction.source ? transaction.source : ""}</p>
                                                </div>

                                            </div>
                                            <div className=" flex flex-col text-left gap-1">
                                                <p className="font-regular text-xs">{transaction.emailAddress ? transaction.emailAddress : ""}</p>
                                                <p className="font-regular text-[9px] text-left rounded-full">{transaction.createdAt ? `${new Date(transaction.createdAt).toDateString()} ${new Date(transaction.createdAt).toLocaleTimeString()}` : ""}</p>
                                                <p className=" items-center truncate font-regular text-[9px] bg-slate-50 px-2 rounded-full">
                                                    {transaction.transactionId}
                                                </p>
                                            </div>
                                        </div>

                                        <p className="font-bold rounded-md bg-white px-2 text-rouzo-deep-blue">{Constants.nairaString}{transaction.amount ? transaction.amount.toLocaleString() : 0}</p>

                                    </div>


                                </div>
                                : ""}
                            {transaction.status == "pending" ?
                                <div className="outline  outline-1 rounded-md  bg-orange-50 outline-orange-300">
                                    {/*<div className="flex flex-row justify-end">
                                            <p className="text-xs text-right bg-orange-400 px-2 pt-0 rounded-md text-white font-bold">pending</p>
                                        </div>*/}
                                    <div className="flex items-center flex-row justify-between gap-4 px-2 py-4 ">
                                        <div className="flex items-center flex-row gap-4 ">
                                            {
                                                transaction.type == "credit" ?
                                                    <FontAwesomeIcon className="text-rouzo-green-light h-4" icon={faArrowAltCircleDown}></FontAwesomeIcon>
                                                    : ""
                                            }
                                            {
                                                transaction.type == "debit" ?
                                                    <FontAwesomeIcon className="text-red-400 h-4" icon={faArrowAltCircleUp}></FontAwesomeIcon>
                                                    : ""
                                            }
                                            <div className="hidden md:flex flex-col gap-1">
                                                <p className=" flex flex-row gap-1 items-center font-regular text-[9px] bg-slate-50 px-2 rounded-full">
                                                    {transaction.transactionId}
                                                    <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
                                                </p>
                                                <div className="flex flex-row text-left justify-start">
                                                    <p className="bg-rouzo-base-color text-white text-[8px] py-0 px-2 rounded-full">{transaction.source ? transaction.source : ""}</p>
                                                </div>

                                            </div>
                                            <div className=" flex flex-col gap-1 text-left">
                                                <p className="font-regular text-xs">{transaction.emailAddress ? transaction.emailAddress : ""}</p>
                                                <p className="font-regular text-[9px] text-left rounded-full">{transaction.createdAt ? `${new Date(transaction.createdAt).toDateString()} ${new Date(transaction.createdAt).toLocaleTimeString()}` : ""}</p>
                                                <p className=" items-center truncate font-regular text-[9px] bg-slate-50 px-2 rounded-full">
                                                    {transaction.transactionId}
                                                </p>
                                            </div>
                                        </div>


                                        <p className="font-bold rounded-md bg-white px-2 text-rouzo-deep-blue">{Constants.nairaString}{transaction.amount ? transaction.amount.toLocaleString() : 0}</p>

                                    </div>


                                </div>
                                : ""}
                        </div>
                    </>
                })
                :
                <div>

                </div>
        }
    </>

}


export default TransactionPageListComponent