import WithdrawalListComponent from "../WithdrawalListComponent";


import { useState, useEffect, useContext } from "react";

import useAccounts from "../../hooks/useAccount";
import { AccountContext } from "../../context/AccountContext";
import { Link } from "react-router-dom";
import { faArrowCircleUp, faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "../../constants/Constants";
import { faArrowAltCircleUp } from "@fortawesome/free-regular-svg-icons";

let WithdrawalPage = () => {
    let { withdrawals } = useContext(AccountContext);
    let { fetchWithdrawals } = useAccounts();

    useEffect(() => {
        if (!withdrawals) {
            fetchWithdrawals()
        }
    })
    return <>
        <div className="flex flex-col gap-2">
            <p className="text-left text-md font-semibold">Withdrawals </p>
            <div className="flex flex-row gap-2">
                <div className="grow">
                    <div className="">

                    </div>
                    <div>
                        <WithdrawalListComponent data={withdrawals}></WithdrawalListComponent>
                    </div>
                </div>
                <div className="flex flex-col gap-2 px-2">
                    <div className="flex flex-row gap-2">
                        <div className="rounded-md p-4 bg-slate-100 drop-shadow-sm flex flex-col gap-2 ">
                            <div className="flex flex-row gap-4 items-center">
                                <div className="rounded-full p-2 circle bg-slate-200">
                                    <FontAwesomeIcon className="text-slate-400" icon={faArrowCircleUp}></FontAwesomeIcon>
                                </div>
                                <div className="text-left flex flex-col">
                                    <p className="font-bold text-[30px]">{withdrawals ? withdrawals.length : 0}</p>
                                    <p className="text-xs text-left">Withdrawals Created</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div>

                            </div>
                        </div>
                        <div className="rounded-md p-4 bg-slate-100 drop-shadow-sm flex flex-col gap-2 ">
                            <div className="flex flex-row gap-4 items-center">
                                <div className="rounded-full p-2 circle bg-slate-200">
                                    <FontAwesomeIcon className="text-slate-400" icon={faArrowAltCircleUp}></FontAwesomeIcon>
                                </div>
                                <div className="text-left flex flex-col">
                                    <p className="font-bold text-[30px]">{Constants.nairaString}{withdrawals ? withdrawals.reduce((a,b) => b.status == "success" ? a + b.amount : 0 ,0) : 0}</p>
                                    <p className="text-xs text-left">Total Withdrawal Value</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div>

                            </div>
                        </div>

                    </div>

                </div>

            </div>



        </div>


    </>
}


export default WithdrawalPage;