

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Routes
} from "react-router-dom";

import Layout from "../layout/Layout";
import OnboardLayout from "../layout/OnboardLayout";
import AccountLayout from "../layout/AccountLayout";
import PrivateRoute from "./privateRoute";
import LoginPage from "../pages/LoginPage";
import DashboardPage from "../pages/DashboardPage";

import EmailVerificationPage from "../pages/email/EmailVerificationPage";
import EmailVerificationStatusPage from "../pages/email/EmailVerificationStatusPage";
import PasswordResetPage from "../pages/password-reset/PasswordResetPage";
import LandingPage from "../pages/home/LandingPage";
import LandingPage2 from "../pages/home/LandingPage2";


const homeRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<Layout />}>
        <Route element={<OnboardLayout />}>
          <Route path="/" element={<LandingPage></LandingPage>}></Route>
        
          <Route path="/login" element={<LoginPage></LoginPage>}></Route>
          <Route path="/verify/:status/:message" element={<EmailVerificationStatusPage></EmailVerificationStatusPage>}></Route>
          
          <Route path="/password-reset/:code" element={<PasswordResetPage></PasswordResetPage>}></Route>
          <Route path="/password-reset" element={<PasswordResetPage></PasswordResetPage>}></Route>
          
          


        </Route>


      </Route>

      <Route element={<Layout />}>
        <Route element={<PrivateRoute />}>
          <Route element={<AccountLayout />}>
            <Route path="/dashboard" element={<DashboardPage></DashboardPage>}></Route>
          </Route>
        </Route>
      </Route>

    </Route >

  )
);


export default homeRouter;
