import axios from "axios";
let development = false;
//layi
export default axios.create({
   baseURL : development ? "http://127.0.0.1:7038/api" : "https://rouzo-boost-backend-2cv7.onrender.com/api",
   //baseURL : development ? "http://127.0.0.1:7038/api" : "https://rouzo-boost-backend-alpha.onrender.com/api",
   //baseURL : development ? "http://127.0.0.1:7038/api" : "https://rouzo-boost-backend-alpha-2jc4.onrender.com/api",
   headers: {
      "Content-Type": "application/json"
   },
   withCredentials : false,
   
})
