import Constants from "../../constants/Constants";
import axios from "../axios";
import authHeader from "./auth-header";
let opt = {
    headers: {
        "Authorization": `${authHeader()}`
    }
}
const AdminService = {
    fetchUsers : async () => {
        let url = Constants.ADMIN.FETCH_USERS;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchTransactions : async () => {
        let url = Constants.ADMIN.FETCH_TRANSACTIONS;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchPlatformSummary2 : async () => {
        let url = Constants.ADMIN.FETCH_PLATFORM_SUMMARY;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchCustomers : async () => {
        let url = Constants.ADMIN.FETCH_CUSTOMERS;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchSavingPlans: async () => {
        //console.log(reference);
        let url = Constants.ADMIN.FETCH_SAVINGPLANS;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchDiscountCodes : async () => {
        let url = Constants.ADMIN.FETCH_DISCOUNT;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchWithdrawals : async () => {
        let url = Constants.ADMIN.FETCH_WITHDRAWALS;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchPaymentLinks : async () => {
        let url = Constants.ADMIN.FETCH_PAYMENT_LINKS;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchWallets : async () => {
        let url = Constants.ADMIN.FETCH_WALLETS;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchLeaderboards : async () => {
        return []
    }
};

export default AdminService;
